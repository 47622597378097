import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Texture } from 'pixi.js';

import { GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';

class Background extends ViewContainer {
  private baseBg = Texture.from(ResourceTypes.slotBg);

  private freeSpinsBg = Texture.from(ResourceTypes.slotBgFreeSpins);

  private bgSprite = new PIXI.Sprite();

  private ambientBaseAnimation: Spine | null = null;

  private appHeight!: number;

  private appWidth!: number;

  private gameMode = GameMode.BASE_GAME;

  constructor() {
    super();

    this.bgSprite.texture = this.baseBg;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);

    //@TODO: no need in animated background for this slot
    // this.initAnimation();
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    this.gameMode = settings.mode;
    this.changeBg();
  }

  private changeBg(): void {
    if (this.gameMode === GameMode.FREE_SPINS) {
      this.bgSprite.texture = this.freeSpinsBg;
    }
    if (this.gameMode === GameMode.BASE_GAME || this.gameMode === GameMode.FREE_ROUND_BONUS) {
      this.bgSprite.texture = this.baseBg;
    }
  }

  private initAnimation(): void {
    this.ambientBaseAnimation = new Spine(PIXI.Loader.shared.resources['ambient']!.spineData!);

    this.addChild(this.ambientBaseAnimation);
    this.ambientBaseAnimation.state.setAnimation(0, 'main_bg', true);
  }

  public override resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;
    this.appWidth = width;
    this.appHeight = height;
    this.changeBg();
    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;
