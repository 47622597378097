import { Spine } from 'pixi-spine';
import { Container, Loader, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EnterProps, EventTypes, GameMode } from '../../global.d';
import { setAvailableBonuses, setCurrentBonus, setIsLeftHandMode, setLostSpinsInARowCount } from '../../gql/cache';
import { Logic } from '../../logic';
import {
  getAwakeiningMeterStateForFreeSpins,
  getAwakeningMeterStateForBet,
  isBrokenGameOnFreeSpins,
} from '../../utils/helper';
import { eventManager } from '../config';

import { MAX_AWAKENING_LEVEL, awakeningMeterPosition } from './awakening-meter.model';

export class AwakeningMeter extends Container {
  public override name = 'AwakeningMeter';
  public static the: AwakeningMeter;

  private spine: Spine;

  private eggsCounter = 0;

  constructor() {
    super();

    const initialAwakeningMeterValueForBaseGame = this.setLostSpinsInARowCount();

    this.eggsCounter = isBrokenGameOnFreeSpins()
      ? getAwakeiningMeterStateForFreeSpins()
      : initialAwakeningMeterValueForBaseGame;
    AwakeningMeter.the = this;

    this.spine = new Spine(Loader.shared.resources['awaking_meter']!.spineData!);
    this.addChild(this.spine);

    this.playAnimation(this.eggsCounter, this.isInitOnBaseGame);

    this.zIndex = 1;
    this.setSizes();
    eventManager.on(EventTypes.RESIZE, () => this.setSizes());
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
  }

  private get isInitOnBaseGame(): boolean {
    return Boolean(!setCurrentBonus().isActive && !setAvailableBonuses().length);
  }

  private setLostSpinsInARowCount() {
    return setLostSpinsInARowCount(getAwakeningMeterStateForBet());
  }

  private setSizes(): void {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const isPortrait = isMobile.any && screenHeight > screenWidth;

    this.width = 300;
    this.height = 320;

    if (!isMobile.any) {
      this.position.set(1150, 430);

      return;
    }
    this.getMobilePosition(isPortrait, setIsLeftHandMode());
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLeftMode) => this.getMobilePosition(isPortrait, isLeftMode));
  }
  private getMobilePosition(isPortrait: boolean, isLeftMode: boolean) {
    const { leftMode, rightMode } = awakeningMeterPosition[isPortrait ? 'portrait' : 'landscape'];
    const { x, y } = isLeftMode ? leftMode : rightMode;
    this.position.set(x, y);
  }
  protected onModeChange(settings: { mode: GameMode; prevMode: GameMode; enterProps: EnterProps }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        if (settings.prevMode !== GameMode.BUY_FEATURE && settings.prevMode !== GameMode.FREE_ROUND_BONUS) {
          this.resetCount();
        }
        this.visible = true;
        break;
      case GameMode.FREE_ROUND_BONUS:
        if (settings.prevMode !== GameMode.BUY_FEATURE && settings.prevMode !== GameMode.BASE_GAME) {
          this.resetCount();
        }
        this.visible = true;
        break;
      case GameMode.FREE_SPINS:
        if (settings.enterProps?.ignoreHideEgg && setAvailableBonuses().length === 0) {
          this.spineReset();
          this.playAnimation(this.eggsCounter, this.isInitOnBaseGame);
        } else {
          this.visible = false;
        }
        break;
      default:
        this.visible = true;
        break;
    }
  }

  private playAnimation(step = 0, isInitial = false, ignoreInAnimation = false): void {
    const inAnimationKey =
      Logic.the.controller.gameMode === GameMode.FREE_SPINS ? `fs_step_${step}_in` : `step_${step}_in`;
    const loopAnimationKey =
      Logic.the.controller.gameMode === GameMode.FREE_SPINS ? `fs_step_${step}_loop` : `step_${step}_loop`;
    const isInAnimationShouldBePlayed =
      Logic.the.controller.gameMode === GameMode.FREE_SPINS ? step !== MAX_AWAKENING_LEVEL : step;
    const isIncreaseSoundShouldBePlayed =
      (Logic.the.controller.gameMode === GameMode.BASE_GAME ||
        Logic.the.controller.gameMode === GameMode.FREE_ROUND_BONUS) &&
      (step || isInitial);

    if (!this.visible) {
      this.visible = true;
    }

    if (isInAnimationShouldBePlayed && !ignoreInAnimation) {
      this.spine.state.setAnimation(0, inAnimationKey, false);
    }

    if (isIncreaseSoundShouldBePlayed) {
      AudioApi.play({ type: ISongs.AwakeningMeter, stopPrev: true });
    }

    this.spine.state.addAnimation(0, loopAnimationKey, true, 0);
  }

  public updateCount(count: number, forceUpdate = false, ignoreInAnimation = false): void {
    const countModified = count <= MAX_AWAKENING_LEVEL ? count : MAX_AWAKENING_LEVEL;
    if (this.eggsCounter !== countModified || forceUpdate) {
      this.eggsCounter = countModified;
      this.playAnimation(this.eggsCounter, false, ignoreInAnimation);
    }
  }

  public resetCount() {
    this.eggsCounter = 0;
    setLostSpinsInARowCount(0);
    this.spineReset();
    this.spine.state.setAnimation(0, `step_${this.eggsCounter}_loop`, true);
  }

  private spineReset() {
    this.spine.destroy();
    this.spine = new Spine(Loader.shared.resources['awaking_meter']!.spineData!);
    this.addChild(this.spine);
  }
}
