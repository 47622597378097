import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EnterProps, EventTypes, GameMode, ISettledBet, UserBonus } from '../../global.d';
import {
  setAvailableBonuses,
  setBetAmount,
  setBetResult,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setIsFreeSpinsWin,
  setIsPopupOpened,
  setIsSlotBusy,
  setIsSpinAndGrabWin,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setLastSpinData,
  setLostSpinsInARowCount,
  setSlotConfig,
  setStressful,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import i18n from '../../i18next';
import SlotMachine from '../../slotMachine';
import { AwakeningMeter } from '../../slotMachine/awakening-meter/awakening-meter';
import { PopupTypes, eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { getAwakeningMeterStateForBet, getBetResult } from '../../utils';
import { getSpinResult } from '../../utils/getSpinResult';
import { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export class FreeRoundBonusController extends BaseController {
  public override gameMode: GameMode = GameMode.FREE_ROUND_BONUS;

  public static override the = new FreeRoundBonusController();

  private skipIdle = false;

  protected constructor() {
    super();

    eventManager.on(EventTypes.FREE_ROUND_BONUS_EXPIRED, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.skipWinAnimation();
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BASE_GAME);
    });
  }

  public override enterIdleState(prevState: States): void {
    if (prevState === States.SPIN) {
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);

      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }
    if (this.skipIdle) {
      this.skipIdle = false;
      return;
    }
    if (prevState === States.TRANSITION) {
      setIsSpinInProgress(false);
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      if (setCurrentBonusId()) {
        return;
      }
    }
    if (prevState === States.BROKEN_GAME) {
      const intervalId = window.setInterval(() => {
        if (eventManager.listenerCount(EventTypes.TOGGLE_SPIN) > 0) {
          setIsPopupOpened(false);
          eventManager.emit(EventTypes.TOGGLE_SPIN);
          window.clearInterval(intervalId);
        }
      }, 50);
      return;
    }

    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
      // AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
    }, 30000);
    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });

    if (setCurrentBonus().currentRound === setCurrentBonus().rounds || !setCurrentBonus().isActive) {
      setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS_END);
      eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      eventManager.once(EventTypes.END_FREE_ROUND_BONUS, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.skipWinAnimation();
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.BASE_GAME);
      });
    }
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout);
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    SlotMachine.the().spinSpinAnimation();
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());

    this.checkBaseWin(betResult);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    setLastRegularWinAmount(winCoinAmount);
    setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
  }

  public override enterJingleState(_prevState: States): void {
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    }
    const result = getBetResult(setBetResult());
    if (result.bet.data.bonuses.length > 0) {
      const freeSpinsFeature = result.bet.data.bonuses.find((e) => !e.isFreeBet);
      if (freeSpinsFeature) {
        setIsFreeSpinsWin(true);
        setFreeRoundsBonus({ ...setCurrentBonus() });
        setFreeSpinsTotalWin(result.bet.result.winCoinAmount);
        setCurrentBonus({
          ...freeSpinsFeature,
          isActive: true,
          currentRound: 0,
        });
        setTimeout(() => {
          Logic.the.skipWinAnimation();
          Logic.the.changeState(States.TRANSITION);
          Logic.the.changeGameMode(GameMode.FREE_SPINS, {
            bonus: freeSpinsFeature,
          });
        }, 1000);
        setAvailableBonuses(result.bet.data.bonuses);
        return;
      }
    }
    Logic.the.changeState(States.IDLE);
  }

  public override setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    setCurrentBonus({
      ...setCurrentBonus(),
      currentRound: setCurrentBonus().currentRound + 1,
    });
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    const freeSpins = result.bet.data?.bonuses?.find((e) => !e.isFreeBet) as UserBonus;

    if (!freeSpins) {
      setFreeRoundsTotalWin(setFreeRoundsTotalWin() + result.bet.result.winCoinAmount);
    }
    setBottomContainerTotalWin(setBottomContainerTotalWin() + result.bet.result.winCoinAmount);
    result.bet.result.spinResult = spinResult;
    setBetResult(result);
    setLastSpinData({
      layout: [],
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
    });
  }

  public override enterController(prevGameMode: GameMode, props: EnterProps): void {
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    if (prevGameMode !== GameMode.BUY_FEATURE && prevGameMode !== GameMode.BASE_GAME) {
      AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
    }

    this.skipIdle = !!props?.skipIdle;
    if (!props?.endBonus && setFreeRoundsBonus().isActive) {
      setCurrentBonus({ ...setFreeRoundsBonus() });
    }
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    setCoinValue(setCurrentBonus().coinValue);
    setCoinAmount(setCurrentBonus().coinAmount);
    setBetAmount(setCurrentBonus().coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);
    eventManager.emit(EventTypes.UPDATE_BET);
    setIsSlotBusy(false);
    setIsFreeSpinsWin(false);
    setIsSpinAndGrabWin(false);
    eventManager.emit(EventTypes.ENABLE_PAYTABLE);

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    setLostSpinsInARowCount(getAwakeningMeterStateForBet());
    AwakeningMeter.the.updateCount(getAwakeningMeterStateForBet(), false, true);
    if (setBottomContainerTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
      }, 0);
    }
    eventManager.once(EventTypes.START_FREE_ROUND_BONUS, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.IDLE);
    });
    if (props?.endBonus && setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound > 0) {
      eventManager.emit(
        EventTypes.UPDATE_FREE_ROUNDS_LEFT,
        setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound,
      );
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
        callback: () => {
          setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          Logic.the.changeState(States.IDLE);
        },
      });
    } else if (setCurrentBonus().rounds - setCurrentBonus().currentRound === 0) {
      Logic.the.changeState(States.IDLE);
    } else if (!setFreeRoundsBonus().isActive || !setFreeRoundsBonus().currentRound) {
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS);
    } else {
      setCurrentBonus({ ...setCurrentBonus(), isActive: true });
      eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
    }
  }

  public override exitController(nextGameMode: GameMode): void {
    if (nextGameMode !== GameMode.BUY_FEATURE && nextGameMode !== GameMode.BASE_GAME) {
      AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
    }
    if (setBrokenGame()) setBrokenGame(false);
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    clearTimeout(this.slotIdleTimeout);
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
  }
}
