import { Group, Layer } from '@pixi/layers';
import { Container, isMobile } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setIsLeftHandMode } from '../../gql/cache';
import { AwakeningMeter } from '../awakening-meter/awakening-meter';
import type { BigWinContainer } from '../bigWinPresentation/bigWinContainer';
import { BuyFeatureBtn } from '../buyFeature/buyFeatureBtn';
import { ViewContainer } from '../components/ViewContainer';
import { FRAME_WIDTH, SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import type { IGameContainer } from '../d';
import { Dragon } from '../dragon/dragon';
import { MultiplierTitle } from '../freeSpins/multiplierTitle';
import type PaylinesContainer from '../paylines/paylinesContainer';
import ReelsFrame from '../reels/background/reelFrame';
import type { ReelsContainer } from '../reels/reelsContainer/reelsContainer';
import type WinCountUpMessage from '../winAnimations/winCountUpMessage';

import { FreeSpinsLeft } from './freeSpinsLeft';
import GameReplay from './gameReplay';

class GameView extends ViewContainer {
  public paylinesContainer: PaylinesContainer;

  public winSlotsContainer: Container;

  public miniPayTableContainer: Container;

  public reelsBackgroundContainer: Container;

  public reelsContainer: ReelsContainer & ViewContainer;

  public tintContainer: Container;

  public slotsContainer: Container;

  public bigWinContainer: BigWinContainer;

  public winCountUpMessage: WinCountUpMessage;

  public freeSpinsLeft: FreeSpinsLeft;

  public reelsFrame: ReelsFrame;

  public dragonContainer: Container;

  public gameReplay: Container;

  private dragon = new Dragon();

  public layer: Layer;

  public layersGroup: Group;
  private isLeftMode = setIsLeftHandMode();

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;

    this.layersGroup = new Group(1, (layer) => {
      layer.zOrder = this.getOrderByLayerName(layer.name);
    });
    this.layer = new Layer(this.layersGroup);
    this.addChild(this.layer);

    this.slotsContainer = new Container();
    this.slotsContainer.name = 'Baraban';
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.y = 130;

    this.slotsContainer.interactive = true;
    this.freeSpinsLeft = new FreeSpinsLeft();
    this.gameReplay = new GameReplay();
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.name = 'winSlotsContainer';
    this.winSlotsContainer.parentGroup = this.layersGroup;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.paylinesContainer = props.paylinesContainer;
    this.tintContainer = props.tintContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.name = 'miniPaytable';
    this.miniPayTableContainer.parentGroup = this.layersGroup;

    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.bigWinContainer = props.bigWinContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.winCountUpMessage.name = 'winCountUpMessage';
    this.winCountUpMessage.parentGroup = this.layersGroup;
    this.reelsFrame = new ReelsFrame();
    this.reelsFrame.name = 'reelsFrame';
    this.reelsFrame.parentGroup = this.layersGroup;
    this.dragonContainer = this.getDragonContainer();
    this.dragonContainer.name = 'dragonContainer';
    this.dragonContainer.parentGroup = this.layersGroup;
    this.slotsContainer.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.paylinesContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.reelsFrame);
    this.slotsContainer.addChild(this.dragonContainer);
    const buyFeatureBtn = new BuyFeatureBtn();
    const awakeningMeter = new AwakeningMeter();
    awakeningMeter.name = 'awakeningMeter';
    const multiplierTitle = new MultiplierTitle();
    multiplierTitle.name = 'multiplierTitle';
    buyFeatureBtn.parentGroup = this.layersGroup;
    awakeningMeter.parentGroup = this.layersGroup;
    multiplierTitle.parentGroup = this.layersGroup;
    this.addChild(this.slotsContainer);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.freeSpinsLeft);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.winCountUpMessage);
    this.addChild(this.bigWinContainer);
    this.addChild(this.gameReplay);
    this.addChild(buyFeatureBtn, awakeningMeter, multiplierTitle);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.SET_IS_LEFT_HAND_MODE, (isLeftMode) => {
      this.isLeftMode = isLeftMode;
      this.positionCorrection();
    });
  }

  private getOrderByLayerName(layerName: string): number {
    switch (layerName) {
      case 'winCountUpMessage':
        return 6;
      case 'awakeningMeter':
        return 5;
      case 'multiplierTitle':
        return 5;
      case 'miniPaytable':
        return 4;
      case 'dragonContainer':
        return 2;
      case 'reelsFrame':
        return 1;
      case 'winSlotsContainer':
        return 0;
    }

    return 0;
  }

  private getDragonContainer(): Container {
    const dragonContainer = new Container();
    dragonContainer.name = 'dragonContainer';
    dragonContainer.addChild(this.dragon);
    dragonContainer.position.set(565, 296.5);

    return dragonContainer;
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.FREE_SPINS:
        this.handleFreeSpinsChanges();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  private handleBaseModeChanges(): void {
    // handleBaseModeChanges
  }

  private handleFreeSpinsChanges(): void {
    // handleFreeSpinsChanges
  }

  private resizeGameContainer(width: number): void {
    const screenWidth = window.innerWidth;
    const isMobilePortrait = isMobile.any && screenWidth < 700;
    const ratio = isMobilePortrait ? 1.25 : screenWidth > 1000 && screenWidth < 1200 ? 0.77 : 1;

    this.scale.set((width / FRAME_WIDTH) * ratio);
    this.positionCorrection();
  }

  private positionCorrection(): void {
    const screenWidth = window.innerWidth;
    const isMobilePortrait = isMobile.any && screenWidth < 700;
    this.x = isMobilePortrait ? -40 : screenWidth > 1000 && screenWidth < 1200 ? 90 : 0;
    this.y = screenWidth > 1000 && screenWidth < 1200 ? 60 : 0;
  }
}

export default GameView;
