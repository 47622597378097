import i18n from 'i18next';
import { Container, Loader, Sprite, Text, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, UserBonus, bonusesId } from '../../global.d';
import {
  setAvailableBonuses,
  setCurrentBonus,
  setIsShowSoundToast,
  setIsSoundLoading,
  setReplayBet,
  setReplayFreeSpinSelectedDragon,
} from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { ResourceTypes } from '../../resources.d';
import { handleChangeRestriction } from '../../utils';
import { AwakeningMeter } from '../awakening-meter/awakening-meter';
import { setSizesByFormFactor } from '../awakening-meter/awakening-meter.model';
import { FeatureButton } from '../components/FeatureButton';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { eventManager } from '../config';
import { Dragon } from '../dragon/dragon';
import { BonusDragons } from '../dragon/dragon.model';
import { ModalService } from '../popups/ModalService';

import {
  dragonActiveText,
  dragonDescriptions,
  dragonPopupTitleText,
  dragonSelectionToGameModeMap,
  dragonText,
  freeSpinsBg,
  freeSpinsDragonSprite,
  freeSpinsPopupSizes,
} from './freeSpins.model';
import { MultiplierTitle } from './multiplierTitle';

export class FreeSpinsModal extends Container {
  public override name = 'FreeSpinsPopup';
  private body = this.getBody();

  private selectedDragon: BonusDragons | null = null;
  private dragons: FeatureButton[] = [];
  private bonusList: UserBonus[];

  constructor(bonusList: UserBonus[]) {
    super();

    this.bonusList = bonusList;

    this.body.addChild(this.getTitle(), this.getDragonContainer(), this.getOkBtn());

    this.addChild(this.body);
    if (setReplayBet()) {
      this.selectedDragon = setReplayFreeSpinSelectedDragon();
      this.dragons.forEach((dragon) => {
        dragon.name === setReplayFreeSpinSelectedDragon() ? dragon.turnOn() : dragon.turnOff();
      });
      setTimeout(() => {
        this.apply();
      }, 2000);
    } else {
      this.selectDragon(BonusDragons.ice);
    }

    setSizesByFormFactor(this.body, freeSpinsPopupSizes);
    eventManager.on(EventTypes.RESIZE, () => {
      setSizesByFormFactor(this.body, freeSpinsPopupSizes);
    });
  }
  private getBody(): Container {
    const background = new Sprite(Texture.from(freeSpinsBg));
    const body = new Container();
    background.width = 728;
    background.height = 512;
    body.addChild(background);

    return body;
  }

  private getTitle(): Text {
    const textField = new TextField(i18n.t<string>('selectDragonTitle'), 301, 64, dragonPopupTitleText);
    textField.text.y = 93;

    this.alignToCenterBody(textField.text);

    return textField.getText();
  }

  private getDragonContainer(): Container {
    const dragonContainer = new Container();

    dragonContainer.y = 166;
    Object.values(BonusDragons).forEach((dragonName, index) => {
      const btn = this.getDragonButton(dragonName);
      if (setReplayBet()) {
        btn.disable();
      }
      btn.x = btn.width / 2 + index * (btn.width + 4);
      btn.y = btn.height / 2;
      dragonContainer.addChild(btn);
      this.dragons.push(btn);
    });
    this.alignToCenterBody(dragonContainer);

    return dragonContainer;
  }

  private getTexFieldForDragonButton(dragon: BonusDragons): TextField {
    const textField = new TextField(i18n.t<string>(dragonDescriptions[dragon]), 147, 64);
    textField.text.y = 160;
    textField.text.anchor.set(0.5, 0.5);

    return textField;
  }

  private getDragonButton(dragon: BonusDragons): FeatureButton {
    const dragonsTextures = Loader.shared.resources[freeSpinsDragonSprite]?.spritesheet?.textures;
    const btn = new FeatureButton({
      [SpriteButtonState.DEFAULT]: {
        texture: dragonsTextures![dragon + '_disabled'] as Texture,
        textStyle: dragonText,
      },
      [SpriteButtonState.HOVER]: {
        texture: dragonsTextures![dragon + '_active'] as Texture,
        textStyle: dragonActiveText,
      },
      [SpriteButtonState.DISABLED]: {
        texture: dragonsTextures![dragon + '_disabled'] as Texture,
        textStyle: dragonText,
      },
      [SpriteButtonState.ACTIVE]: {
        texture: dragonsTextures![dragon + '_active'] as Texture,
        textStyle: dragonActiveText,
      },
      textFiled: this.getTexFieldForDragonButton(dragon),
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => this.selectDragon(dragon),
      onTouchStart: () => this.selectDragon(dragon),
      isActive: false,
    });

    btn.name = dragon;
    btn.width = 191;
    btn.height = 178;

    return btn;
  }

  private selectDragon(name: BonusDragons): void {
    if (setReplayBet()) return;
    AudioApi.play({ type: ISongs.DragonTypeSelection, stopPrev: true });

    this.selectedDragon = name;
    this.dragons.forEach((dragon) => {
      dragon.name === name ? dragon.turnOn() : dragon.turnOff();
    });
  }

  private getOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => this.apply(),
      onTouchStart: () => this.apply(),
    });
    okBtn.height = 48;
    okBtn.width = 96;
    okBtn.y = 428;
    okBtn.anchor.set(0, 0);
    if (setReplayBet()) {
      okBtn.disable();
    }
    this.alignToCenterBody(okBtn);

    return okBtn;
  }

  private apply(): void {
    const selectedBonusId: string = bonusesId[
      dragonSelectionToGameModeMap[this.selectedDragon as BonusDragons]
    ] as string;
    const selectedBonus = this.bonusList.filter((bonus) => bonus.bonusId === selectedBonusId)[0] as UserBonus;

    if (!AudioApi.isInitialized) {
      eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
    }
    AudioApi.changeRestriction(
      false,
      [],
      () => setIsSoundLoading(true),
      () => {
        setIsShowSoundToast(false);
        handleChangeRestriction(Logic.the.controller.gameMode);
      },
    );

    ModalService.the.hide();
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, () => {
      setCurrentBonus({
        ...selectedBonus,
        isActive: true,
        roundsLeft: selectedBonus.rounds,
        currentMultiplier: selectedBonus.data.dragonMultiplier,
      });
      setAvailableBonuses([]);

      AudioApi.stop({ type: ISongs.BGM_SD_Loop });
      AudioApi.play({ type: ISongs.BGM_FS_Loop });

      Dragon.the.setDragon(this.selectedDragon as BonusDragons);
      MultiplierTitle.the.change(setCurrentBonus().currentMultiplier);
      AwakeningMeter.the.updateCount(setCurrentBonus().roundsLeft, true);

      this.startFreeSpins();
    });
  }

  private startFreeSpins() {
    eventManager.emit(EventTypes.START_FREE_SPINS);
    Logic.the.changeState(States.IDLE);
  }

  private alignToCenterBody(element: Container): void {
    element.x = (this.body.width - element.width * this.body.scale.x) / 2 / this.body.scale.x;
  }
}
