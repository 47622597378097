import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import {
  BonusStatus,
  EnterProps,
  EventTypes,
  GameMode,
  ISettledBet,
  IUserBalance,
  UserBonus,
  bonusesId,
} from '../../global.d';
import {
  setAvailableBonuses,
  setBetAmount,
  setBetResult,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setGameHistory,
  setIsBuyFeatureFailed,
  setIsFreeSpinsWin,
  setIsProceedToGame,
  setIsSlotBusy,
  setIsSpinAndGrabWin,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setLastSpinData,
  setLostSpinsInARowCount,
  setReplayBet,
  setReplayFreeSpinBets,
  setSkipIntroScreen,
  setSlotConfig,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { getUserGql, isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { AwakeningMeter } from '../../slotMachine/awakening-meter/awakening-meter';
import { WinStages, eventManager } from '../../slotMachine/config';
import IntroScreen from '../../slotMachine/introScreen/introScreen';
import { getAwakeningMeterStateForBet, getBetResult, getWinStage, saveReelPosition, showCurrency } from '../../utils';
import { getSpinResult } from '../../utils/getSpinResult';
import { States } from '../config';
import { Logic } from '../index';

import { Controller } from './Controller';

export class BaseController extends Controller {
  public gameMode: GameMode = GameMode.BASE_GAME;

  public static the = new BaseController();

  public slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();
  }

  public override enterInitState(_prevState: States): void {
    if (!setSkipIntroScreen()) {
      Logic.the.changeState(States.INTRO);
      return;
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.BROKEN_GAME);
      return;
    }

    Logic.the.changeState(States.IDLE);
  }

  public override exitInitState(nextState: States): void {
    if (nextState === States.INTRO) return;

    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
    if (nextState === States.IDLE) {
      setIsProceedToGame(true);
    }
  }

  public override enterIntroState(_prevState: States): void {
    IntroScreen.init();
    eventManager.emit(EventTypes.FORCE_RESIZE);
    eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      if (setBrokenGame()) {
        Logic.the.changeState(States.BROKEN_GAME);
        return;
      }
      Logic.the.changeState(States.IDLE);
    });
  }

  public override exitIntroState(_nextState: States): void {
    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
  }

  public override enterBrokenGameState(_prevState: States): void {
    setIsProceedToGame(true);
    if (!setCurrentBonusId()) {
      const bonus = setCurrentBonus();
      SlotMachine.the().onBrokenGame(bonus);
      Logic.the.skipWinAnimation();
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(bonus.gameMode, {
        bonus,
        immediate: true,
        skipIdle: bonus.gameMode === GameMode.FREE_ROUND_BONUS,
        ignoreHideEgg: true,
      });
      if (bonus.gameMode === GameMode.FREE_ROUND_BONUS) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      }
    } else {
      if (setReplayBet() && setCurrentBonusId()) {
        const bonus = setCurrentBonus();
        SlotMachine.the().onBrokenGame(bonus);
        Logic.the.skipWinAnimation();
      }
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterIdleState(prevState: States): void {
    if (prevState === States.SPIN) {
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);

      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }
    if (prevState === States.TRANSITION) {
      setIsSpinInProgress(false);
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      if (setCurrentBonusId()) {
        return;
      }
    }

    if (prevState === States.INIT || prevState === States.INTRO || setIsBuyFeatureFailed()) {
      setIsBuyFeatureFailed(false);
      // const debug = new Debug();
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    if (prevState === States.BROKEN_GAME) {
      setTimeout(() => {
        eventManager.emit(EventTypes.TOGGLE_SPIN);
      }, 100);

      return;
    }
    // when FRB is expired, you dont have result
    // setBetResult is null and you have to call query
    // to update balance.
    if (!setBetResult()) {
      client
        .query<{ user: IUserBalance }>({
          query: getUserGql,
          fetchPolicy: 'network-only',
        })
        .then((userBalance) => {
          setUserBalance(userBalance.data.user);
          eventManager.emit(EventTypes.UPDATE_USER_BALANCE, userBalance.data.user.balance);
        });
      return;
    }
    if (prevState === States.BEFORE_WIN) {
      const betResult = getBetResult(setBetResult());
      // its locally for testing.
      // betResult.bet.data.bonuses.push({
      //   ...(setCurrentBonus() as UserBonus),
      //   isActive: true,
      //   gameMode: GameMode.FREE_ROUND_BONUS,
      //   currentRound: 0,
      //   rounds: 5,
      //   totalWinAmount: 0,
      //   coinAmount: 1,
      //   coinValue: 1000,
      //   id: '3bfe6745-0d39-40b3-80d7-22d2e331a912',
      //   isFreeBet: true,
      //   bonusId: '3bfe6745-0d39-40b3-80d7-22d2e331a912',
      // });
      const frbBonus = betResult.bet.data.bonuses.find((e) => e.isFreeBet);
      if (frbBonus && frbBonus.status !== BonusStatus.SETTLED) {
        eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
        setCurrentBonus({
          ...frbBonus,
          gameMode: GameMode.FREE_ROUND_BONUS,
          rounds: frbBonus.rounds,
          isActive: true,
          currentRound: 0,
          coinAmount: frbBonus.coinAmount,
          coinValue: frbBonus.coinValue,
        });
        eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds);
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.FREE_ROUND_BONUS);
        return;
      }
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
      // AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
    }, 30000);
    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    this.handleHistory(prevState === States.TRANSITION);
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout);
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    SlotMachine.the().spinSpinAnimation();
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());

    this.checkBaseWin(betResult);
  }

  public override enterWinLinePresentationState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    const { paylines } = betResult;
    const { winCoinAmount } = betResult.bet.result;
    // const multiplier = normalizeCoins(winCoinAmount) / normalizeCoins(setBetAmount());
    // if (multiplier >= 5 && multiplier < 10) {
    //   AudioApi.play({ type: ISongs.HighWin, stopPrev: true });
    // }
    // if (multiplier >= 2 && multiplier < 5) {
    //   AudioApi.play({ type: ISongs.MediumWin, stopPrev: true });
    // }
    // if (multiplier > 0 && multiplier < 2) {
    //   AudioApi.play({ type: ISongs.SmallWin, stopPrev: true });
    // }

    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        Logic.the.changeState(States.AFTER_WIN);
      });
      setTimeout(() => eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount), 1000);
    } else {
      eventManager.once(EventTypes.WIN_LINE_ANIMATION_END, () => {
        Logic.the.changeState(States.AFTER_WIN);
      });
      eventManager.emit(EventTypes.START_COUNT_UP, 0, winCoinAmount, 0);
    }

    eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult, paylines);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    setWinAmount(winCoinAmount);
    setLastRegularWinAmount(winCoinAmount);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
    setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
  }

  public override enterJingleState(_prevState: States): void {
    const result = getBetResult(setBetResult());
    // its locally for testing.
    // result.bet.data.bonuses.push({
    //   ...(setCurrentBonus() as UserBonus),
    //   isActive: true,
    //   gameMode: GameMode.FREE_ROUND_BONUS,
    //   currentRound: 0,
    //   rounds: 5,
    //   totalWinAmount: 0,
    //   coinAmount: 1,
    //   coinValue: 1000,
    //   id: '3bfe6745-0d39-40b3-80d7-22d2e331a912',
    //   isFreeBet: true,
    //   bonusId: '3bfe6745-0d39-40b3-80d7-22d2e331a912',
    // });
    const isFeatureWin = result.bet.data.bonuses.length > 0;

    if (isFeatureWin) {
      const freeRoundBonus = result.bet.data.bonuses.find((e) => e.isFreeBet);
      const fsBonus = result.bet.data.bonuses.find((e) => e.bonusId !== bonusesId[GameMode.FREE_ROUND_BONUS]);
      // if we have only 1 bonus and its FRB.
      if (result.bet.data.bonuses.length === 1 && freeRoundBonus) {
        eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
        setBottomContainerTotalWin(0);
        setFreeRoundsTotalWin(0);
        setCurrentBonus({
          ...(freeRoundBonus as UserBonus),
          gameMode: GameMode.FREE_ROUND_BONUS,
          rounds: freeRoundBonus.rounds,
          isActive: true,
          currentRound: 0,
          coinAmount: freeRoundBonus.coinAmount,
          coinValue: freeRoundBonus.coinValue,
        });
        eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds);
        setCoinValue(freeRoundBonus.coinValue);
        setCoinAmount(freeRoundBonus.coinAmount);
        setBetAmount(freeRoundBonus.coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);
        eventManager.emit(EventTypes.UPDATE_BET);
        const replayFreeSpins = setReplayFreeSpinBets();
        Logic.the.skipWinAnimation();
        if (setReplayBet() && !replayFreeSpins.length) {
          Logic.the.changeState(States.IDLE);
          return;
        }

        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.FREE_ROUND_BONUS, {
          bonus: freeRoundBonus as UserBonus,
        });
        return;
      }
      if (fsBonus) {
        setIsFreeSpinsWin(true);
        setCurrentBonus({
          ...(fsBonus as UserBonus),
          isActive: true,
          currentRound: 0,
        });
        setFreeSpinsTotalWin(result.bet.result.winCoinAmount);
        setBottomContainerTotalWin(result.bet.result.winCoinAmount);
        const replayFreeSpins = setReplayFreeSpinBets();
        if (setReplayBet() && !replayFreeSpins.length) {
          Logic.the.skipWinAnimation();
          Logic.the.changeState(States.IDLE);
          return;
        }
        setTimeout(() => {
          Logic.the.skipWinAnimation();
          Logic.the.changeState(States.TRANSITION);
          Logic.the.changeGameMode(GameMode.FREE_SPINS, {
            bonus: fsBonus as UserBonus,
          });
          setAvailableBonuses(result.bet.data.bonuses);
        }, 1000);
      }

      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public override enterController(prevGameMode: GameMode, _props?: EnterProps): void {
    if (prevGameMode !== GameMode.BUY_FEATURE && prevGameMode !== GameMode.FREE_ROUND_BONUS) {
      AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
    }
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.IMMEDIATE_CLOSE_EYES);
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BUY_FEATURE, { bonusId });
    });
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);
    setIsSpinAndGrabWin(false);
    if (prevGameMode === GameMode.FREE_SPINS || prevGameMode === GameMode.FREE_ROUND_BONUS) {
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      setWinAmount(setBottomContainerTotalWin());
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({
          currency: setCurrency(),
          value: setBottomContainerTotalWin(),
          showCurrency: showCurrency(setCurrency()),
        }),
      );
      setBottomContainerTotalWin(0);
      setLostSpinsInARowCount(getAwakeningMeterStateForBet());
      AwakeningMeter.the.updateCount(getAwakeningMeterStateForBet(), false, true);
    }
    Logic.the.changeState(States.IDLE);
  }

  public override exitController(nextGameMode: GameMode): void {
    if (nextGameMode !== GameMode.BUY_FEATURE && nextGameMode !== GameMode.FREE_ROUND_BONUS) {
      AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
    }
    clearTimeout(this.slotIdleTimeout);
    eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS);
  }

  public override setResult(result: ISettledBet): void {
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });

    const newResult = JSON.parse(JSON.stringify(result));
    newResult.bet.result.spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    setBetResult(newResult);

    setLastSpinData({
      layout: [],
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
    });
  }

  private handleHistory(skipSoundChange: boolean): void {
    const betResult = getBetResult(setBetResult());
    const win = betResult.bet.result.winCoinAmount;
    const lastThreeSpins = [...setGameHistory().slice(1), !!win];
    const isThirdWinInRow = lastThreeSpins.every((val) => Boolean(val));
    const isThirdLoseInRow = lastThreeSpins.every((val) => !val);
    const isWinThreeTimesBiggerThanBet = setBetAmount() * 3 <= win;

    if (!skipSoundChange) {
      if (isWinThreeTimesBiggerThanBet || isThirdWinInRow) {
        // AudioApi.fadeOut(500, ISongs.BGM_BG_Base_Loop);
        AudioApi.fadeIn(500, ISongs.BGM_BG_Melo_Loop);
      }

      if (isThirdLoseInRow) {
        AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
        // AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
      }
    }

    setGameHistory(lastThreeSpins);
    setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });
    saveReelPosition(betResult.bet.result.reelPositions);
  }

  public checkBaseWin(betResult: ISettledBet): void {
    if (betResult.paylines.length) {
      const awakeningLevel = betResult.bet.result.winCoinAmount
        ? setLostSpinsInARowCount(0)
        : setLostSpinsInARowCount(setLostSpinsInARowCount() + 1);
      this.updateAwakeningMeter(awakeningLevel);
      Logic.the.changeState(States.WIN_LINE_PRESENTATION);
    } else {
      this.updateAwakeningMeter(setLostSpinsInARowCount(setLostSpinsInARowCount() + 1));
      Logic.the.changeState(States.IDLE);
    }
  }

  private updateAwakeningMeter(awakeningLevel: number) {
    AwakeningMeter.the.updateCount(awakeningLevel);
  }
}
